
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getDiscountCampaignsData: "general/getDiscountCampaignsData",
    }),
  },
};
